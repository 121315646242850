var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content_out",style:({ paddingTop: _vm.videoHeight + 'px' })},[_c('div',{ref:"video",staticClass:"video_out_out"},[_c('div',{staticClass:"video_out"},[_c('video',{staticClass:"video",attrs:{"src":"https://qn.juquedu.com/juqu_video.mp4","loop":"loop","muted":"muted","preload":"auto","autoplay":"autoplay"},domProps:{"muted":true}})]),_c('div',{staticClass:"video_mask"}),_c('div',{staticClass:"video_content flexVerticalCenter"},[_c('div',{staticClass:"video_big_title"},[_vm._v(" 累计服务 "),_c('div',[_vm._v(" "+_vm._s(_vm.allData.total)+" ")]),_vm._v(" 位 ")]),_c('img',{staticClass:"video_english_img",attrs:{"src":"https://qn.juquedu.com/top_text_img.png","alt":"","srcset":""}}),_c('div',{staticClass:"video_small_title"},[_vm._v("留学用户")]),_c('div',{staticClass:"video_service_num flexVerticalCenter"},[_c('div',{staticClass:"video_service_num_bg"}),_vm._v(" 本月服务人数："+_vm._s(_vm.allData.monthTotal)+"位 ")])])]),_c('div',{ref:"juqu",staticClass:"juqu_out"},[_c('div',{staticClass:"juqu"},[_c('div',{staticClass:"bg_title_out flexVerticalCenter"},[_c('div',{class:_vm.juquScroll && _vm.juquTime == 0
							? 'bg_title bg_title_apear'
							: 'bg_title',style:({
						opacity: _vm.juquTime == 1 ? '0.1' : '0',
						'animation-delay': '0ms'
					})},[_vm._v(" ABOUT ")]),_c('div',{staticClass:"title_out flexVerticalCenter"},[_c('div',{class:_vm.juquScroll && _vm.juquTime == 0
								? 'big_title title_apear'
								: 'big_title',style:({
							opacity: _vm.juquTime == 1 ? '1' : '0',
							'animation-delay': '45ms'
						})},[_vm._v(" 2022 ")]),_c('div',{class:_vm.juquScroll && _vm.juquTime == 0
								? 'big_title title_apear'
								: 'big_title',style:({
							opacity: _vm.juquTime == 1 ? '1' : '0',
							'animation-delay': '45ms',
							marginTop: '10px'
						})},[_vm._v(" 年度影响力在线教育品牌 ")])])]),_c('div',{class:_vm.juquScroll && _vm.juquTime == 0
						? 'juqu_content_out content_apear'
						: 'juqu_content_out',style:({
					opacity: _vm.juquTime == 1 ? '1' : '0',
					'animation-delay': '200ms'
				})},[_c('img',{staticClass:"juqu_content_icon",attrs:{"src":"https://qn.juquedu.com/video_icon.png","alt":"","srcset":""}}),_c('video',{ref:"video2",staticStyle:{"width":"100%","position":"relative","margin-top":"64px","border-radius":"48px"},attrs:{"id":"mv","src":"https://qn.juquedu.com/71bce58eafa346f8b9a0c015a0ad4783","poster":_vm.poster,"x5-playsinline":"true","webkit-playsinline":"true","playsinline":"true","controls":"","muted":"muted","preload":"auto","autoplay":"autoplay"},domProps:{"muted":true}})])])]),_c('div',{ref:"service",staticClass:"service_out"},[_c('div',{staticClass:"service_inner"},[_c('div',{staticClass:"bg_title_box flexBetween"},[_c('div',{staticClass:"bg_title_out"},[_c('div',{class:_vm.studyScroll && _vm.studyTime == 0
								? 'bg_title2 bg_title_apear2'
								: 'bg_title2',style:({
							opacity: _vm.studyTime == 1 ? '1' : '0',
							'animation-delay': '0ms'
						})},[_vm._v(" ONE-STOP STUDY ABROAD ")]),_c('div',{staticClass:"title_out2"},[_c('div',{class:_vm.studyScroll && _vm.studyTime == 0
									? 'big_title2 title_apear2'
									: 'big_title2',style:({
								opacity: _vm.studyTime == 1 ? '1' : '0',
								'animation-delay': '45ms'
							})},[_vm._v(" 我们致力于提供一流的"),_c('span',[_vm._v("一站式留学")]),_vm._v("服务， ")]),_c('div',{class:_vm.studyScroll && _vm.studyTime == 0
									? 'small_title2 title_apear2'
									: 'small_title2',style:({
								opacity: _vm.studyTime == 1 ? '1' : '0',
								'animation-delay': '90ms'
							})},[_vm._v(" 让您畅享无忧留学之旅 ")])])]),_c('div',{staticClass:"service_contact flexVerticalCenter",on:{"click":_vm.openContactPopup}},[_c('img',{staticClass:"service_contact_img",attrs:{"src":"https://qn.juquedu.com/wechat_white_icon.png","alt":"","srcset":""}}),_vm._v(" 联系我们 ")])]),_vm._l((_vm.studyList),function(item,a){return _c('div',{key:a + 's',class:_vm.studyScroll && _vm.studyTime == 0
						? 'service content_apear'
						: 'service',style:({
					opacity: _vm.studyTime == 1 ? '1' : '0',
					'animation-delay': '200ms',
					backgroundImage: 'url(' + item.img + ')'
				})},[_c('div',{staticClass:"service_title"},[_vm._v(" "+_vm._s(item.title)),(a == 3)?_c('span',[_vm._v("（&MORE）")]):_vm._e()]),_vm._l((item.content),function(item2,b){return _c('div',{key:b + 'sc',staticClass:"service_content"},[_vm._v(" "+_vm._s(item2)+" ")])})],2)})],2)]),_c('div',{staticStyle:{"clear":"both"}}),_c('div',{ref:"country",staticClass:"country_out"},[_c('div',{staticClass:"bg_title_out"},[_c('div',{class:_vm.countryScroll && _vm.countryTime == 0
						? 'bg_title2 bg_title_apear2'
						: 'bg_title2',style:({
					opacity: _vm.countryTime == 1 ? '1' : '0',
					'animation-delay': '0ms',
					textAlign: 'center'
				})},[_vm._v(" STUDY COUNTRY ")]),_c('div',{staticClass:"title_out2 flexVerticalCenter",style:({ marginBottom: '40px' })},[_c('div',{class:_vm.countryScroll && _vm.countryTime == 0
							? 'big_title2 title_apear2'
							: 'big_title2',style:({
						opacity: _vm.countryTime == 1 ? '1' : '0',
						'animation-delay': '45ms',
						textAlign: 'center',
						paddingBottom: '0'
					})},[_vm._v(" 留学国家推荐 ")])])]),_c('div',{class:_vm.countryScroll && _vm.countryTime == 0
					? 'country content_apear'
					: 'country',style:({
				opacity: _vm.countryTime == 1 ? '1' : '0'
			})},[_c('div',{staticClass:"country_list flexVerticalCenter"},[_vm._m(0),_vm._l((_vm.countryList),function(item,c){return _c('div',{key:c + 'c',staticClass:"country_item flexVerticalCenter"},[_c('div',{staticClass:"country_info"},[_c('div',{staticClass:"country_chinese flexVerticalCenter"},[_vm._v(" "+_vm._s(item.name)+" "),_c('img',{staticClass:"country_flag",attrs:{"src":item.img1,"alt":"","srcset":""}})]),_c('div',{staticClass:"country_num"},[_vm._v(" 服务人数："),_c('span',[_vm._v(_vm._s(item.serviceCount))]),_vm._v("位 ")]),_c('div',{staticClass:"country_original"},[_vm._v(" "+_vm._s(item.ename)+" ")]),_c('div',{staticClass:"country_more flexVerticalCenter",on:{"click":function($event){return _vm.toPage(item)}}},[_vm._v(" 了解更多 ")])]),_c('div',{staticClass:"country_flag_box flexVerticalCenter"},[_c('div',{staticClass:"country_flag_bg flexVerticalCenter"},[_c('img',{staticClass:"country_sign",attrs:{"src":item.backImg1,"alt":"","srcset":""}})])])])})],2)]),_c('div',{staticClass:"bg_title_out",staticStyle:{"padding-top":"156px"}},[_c('div',{class:_vm.teacherScroll && _vm.teacherTime == 0
						? 'bg_title2 bg_title_apear2'
						: 'bg_title2',style:({
					opacity: _vm.teacherTime == 1 ? '1' : '0',
					'animation-delay': '0ms',
					textAlign: 'center'
				})},[_vm._v(" FACULTY TEAM ")]),_c('div',{staticClass:"title_out2 flexVerticalCenter",style:({ marginBottom: '40px' })},[_c('div',{class:_vm.teacherScroll && _vm.teacherTime == 0
							? 'big_title2 title_apear2'
							: 'big_title2',style:({
						opacity: _vm.teacherTime == 1 ? '1' : '0',
						'animation-delay': '45ms',
						textAlign: 'center',
						paddingBottom: '0'
					})},[_vm._v(" 专业海归顾问团队 ")])])])]),_c('div',{ref:"teacher",staticClass:"teacher_out"},[_c('div',{class:_vm.teacherScroll && _vm.teacherTime == 0
					? 'teacher_small_title title_apear2'
					: 'teacher_small_title',style:({
				opacity: _vm.teacherTime == 1 ? '1' : '0',
				'animation-delay': '90ms',
				textAlign: 'center'
			})},[_vm._v(" 为您提供丰富经验与知识的留学支持 ")]),_c('div',{class:_vm.teacherScroll && _vm.teacherTime == 0 ? 'content_apear' : '',style:({
				opacity: _vm.teacherTime == 1 ? '1' : '0'
			})},[_c('div',{staticClass:"teacher_list_out_out flexBetween"},[(_vm.canScroll)?_c('div',{staticClass:"teacher_btn",on:{"click":function($event){return _vm.fnPrev()}}},[(_vm.currentClickNumber > 1)?_c('img',{staticClass:"btn_active_icon",attrs:{"src":"https://qn.juquedu.com/left_active_icon.png","alt":""}}):_c('img',{staticClass:"btn_active_icon",attrs:{"src":"https://qn.juquedu.com/left_icon.png","alt":""}})]):_vm._e(),_c('div',{staticClass:"teacher_list_out flexBetween"},[_c('div',{ref:"fixedBox",staticClass:"teacher_list_box"},[_c('div',{staticClass:"teacher_list flexCenter",style:(("width:" + (_vm.signleWidth *
									_vm.teacherList.length) + "px;transform:translate(" + _vm.scrollResultWidth + "px,0);transition:0.7s;"))},_vm._l((_vm.teacherList),function(item,d){return _c('div',{key:d + 't',staticClass:"teacher_imgs flexVerticalCenter"},[_c('img',{staticStyle:{"border-radius":"16px","width":"100%","height":"100%"},attrs:{"src":item.img1,"alt":""}}),_c('div',{staticClass:"teacher_imgs_bg_box"},[_c('div',{staticClass:"teacher_imgs_bg"}),_c('div',{staticClass:"teacher_details"},[_c('div',{staticClass:"teacher_name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"teacher_des"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"teacher_introduce"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])])}),0)])]),(_vm.canScroll)?_c('div',{staticClass:"teacher_btn",on:{"click":function($event){return _vm.fnNext(_vm.activeName)}}},[(_vm.noScrollRight && _vm.teacherList.length > 4)?_c('img',{staticClass:"btn_active_icon",attrs:{"src":"https://qn.juquedu.com/right_active_icon.png","alt":""}}):_c('img',{staticClass:"btn_active_icon",attrs:{"src":"https://qn.juquedu.com/right_icon.png","alt":""}})]):_vm._e()])])]),_c('ContactPopup',{ref:"contactPopup"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"country_bg flexVerticalCenter"},[_c('img',{staticClass:"country_earth",attrs:{"src":"https://qn.juquedu.com/earth_img.png","alt":"","srcset":""}})])}]

export { render, staticRenderFns }