<template>
	<div class="content_out" :style="{ paddingTop: videoHeight + 'px' }">
		<!-- 视频 -->
		<div ref="video" class="video_out_out">
			<div class="video_out">
				<video
					class="video"
					src="https://qn.juquedu.com/juqu_video.mp4"
					loop="loop"
					muted="muted"
					preload="auto"
					autoplay="autoplay"
				></video>
			</div>
			<div class="video_mask"></div>
			<div class="video_content flexVerticalCenter">
				<div class="video_big_title">
					累计服务
					<div>
						{{ allData.total }}
					</div>
					位
				</div>
				<img
					class="video_english_img"
					src="https://qn.juquedu.com/top_text_img.png"
					alt=""
					srcset=""
				/>
				<div class="video_small_title">留学用户</div>
				<div class="video_service_num flexVerticalCenter">
					<div class="video_service_num_bg"></div>
					本月服务人数：{{ allData.monthTotal }}位
				</div>
			</div>
		</div>
		<!-- 关于聚趣 -->
		<div ref="juqu" class="juqu_out">
			<div class="juqu">
				<div class="bg_title_out flexVerticalCenter">
					<div
						:class="
							juquScroll && juquTime == 0
								? 'bg_title bg_title_apear'
								: 'bg_title'
						"
						:style="{
							opacity: juquTime == 1 ? '0.1' : '0',
							'animation-delay': '0ms'
						}"
					>
						ABOUT
					</div>
					<div class="title_out flexVerticalCenter">
						<div
							:class="
								juquScroll && juquTime == 0
									? 'big_title title_apear'
									: 'big_title'
							"
							:style="{
								opacity: juquTime == 1 ? '1' : '0',
								'animation-delay': '45ms'
							}"
						>
							2022
						</div>
						<div
							:class="
								juquScroll && juquTime == 0
									? 'big_title title_apear'
									: 'big_title'
							"
							:style="{
								opacity: juquTime == 1 ? '1' : '0',
								'animation-delay': '45ms',
								marginTop: '10px'
							}"
						>
							年度影响力在线教育品牌
						</div>
					</div>
				</div>
				<div
					:class="
						juquScroll && juquTime == 0
							? 'juqu_content_out content_apear'
							: 'juqu_content_out'
					"
					:style="{
						opacity: juquTime == 1 ? '1' : '0',
						'animation-delay': '200ms'
					}"
				>
					<img
						class="juqu_content_icon"
						src="https://qn.juquedu.com/video_icon.png"
						alt=""
						srcset=""
					/>
					<video
						ref="video2"
						style="width: 100%; position: relative;margin-top:64px;border-radius: 48px;"
						id="mv"
						src="https://qn.juquedu.com/71bce58eafa346f8b9a0c015a0ad4783"
						:poster="poster"
						x5-playsinline="true"
						webkit-playsinline="true"
						playsinline="true"
						controls
						muted="muted"
						preload="auto"
						autoplay="autoplay"
					></video>
				</div>
			</div>
		</div>
		<!-- 留学服务 -->
		<div ref="service" class="service_out">
			<div class="service_inner">
				<div class="bg_title_box flexBetween">
					<div class="bg_title_out">
						<div
							:class="
								studyScroll && studyTime == 0
									? 'bg_title2 bg_title_apear2'
									: 'bg_title2'
							"
							:style="{
								opacity: studyTime == 1 ? '1' : '0',
								'animation-delay': '0ms'
							}"
						>
							ONE-STOP STUDY ABROAD
						</div>
						<div class="title_out2">
							<div
								:class="
									studyScroll && studyTime == 0
										? 'big_title2 title_apear2'
										: 'big_title2'
								"
								:style="{
									opacity: studyTime == 1 ? '1' : '0',
									'animation-delay': '45ms'
								}"
							>
								我们致力于提供一流的<span>一站式留学</span>服务，
							</div>
							<div
								:class="
									studyScroll && studyTime == 0
										? 'small_title2 title_apear2'
										: 'small_title2'
								"
								:style="{
									opacity: studyTime == 1 ? '1' : '0',
									'animation-delay': '90ms'
								}"
							>
								让您畅享无忧留学之旅
							</div>
						</div>
					</div>
					<div
						class="service_contact flexVerticalCenter"
						@click="openContactPopup"
					>
						<img
							class="service_contact_img"
							src="https://qn.juquedu.com/wechat_white_icon.png"
							alt=""
							srcset=""
						/>
						联系我们
					</div>
				</div>
				<div
					:class="
						studyScroll && studyTime == 0
							? 'service content_apear'
							: 'service'
					"
					v-for="(item, a) in studyList"
					:key="a + 's'"
					:style="{
						opacity: studyTime == 1 ? '1' : '0',
						'animation-delay': '200ms',
						backgroundImage: 'url(' + item.img + ')'
					}"
				>
					<div class="service_title">
						{{ item.title }}<span v-if="a == 3">（&MORE）</span>
					</div>
					<div
						class="service_content"
						v-for="(item2, b) in item.content"
						:key="b + 'sc'"
					>
						{{ item2 }}
					</div>
				</div>
			</div>
		</div>
		<div style="clear: both;"></div>
		<!-- 留学国家推荐 -->
		<div ref="country" class="country_out">
			<div class="bg_title_out">
				<div
					:class="
						countryScroll && countryTime == 0
							? 'bg_title2 bg_title_apear2'
							: 'bg_title2'
					"
					:style="{
						opacity: countryTime == 1 ? '1' : '0',
						'animation-delay': '0ms',
						textAlign: 'center'
					}"
				>
					STUDY COUNTRY
				</div>
				<div
					class="title_out2 flexVerticalCenter"
					:style="{ marginBottom: '40px' }"
				>
					<div
						:class="
							countryScroll && countryTime == 0
								? 'big_title2 title_apear2'
								: 'big_title2'
						"
						:style="{
							opacity: countryTime == 1 ? '1' : '0',
							'animation-delay': '45ms',
							textAlign: 'center',
							paddingBottom: '0'
						}"
					>
						留学国家推荐
					</div>
				</div>
			</div>
			<div
				:class="
					countryScroll && countryTime == 0
						? 'country content_apear'
						: 'country'
				"
				:style="{
					opacity: countryTime == 1 ? '1' : '0'
				}"
			>
				<div class="country_list flexVerticalCenter">
					<div class="country_bg flexVerticalCenter">
						<img
							class="country_earth"
							src="https://qn.juquedu.com/earth_img.png"
							alt=""
							srcset=""
						/>
					</div>
					<div
						class="country_item flexVerticalCenter"
						v-for="(item, c) in countryList"
						:key="c + 'c'"
					>
						<div class="country_info">
							<div class="country_chinese flexVerticalCenter">
								{{ item.name }}
								<img
									class="country_flag"
									:src="item.img1"
									alt=""
									srcset=""
								/>
							</div>
							<div class="country_num">
								服务人数：<span>{{ item.serviceCount }}</span
								>位
							</div>
							<div class="country_original">
								{{ item.ename }}
							</div>
							<div
								class="country_more flexVerticalCenter"
								@click="toPage(item)"
							>
								了解更多
							</div>
						</div>
						<div class="country_flag_box flexVerticalCenter">
							<div class="country_flag_bg flexVerticalCenter">
								<img
									class="country_sign"
									:src="item.backImg1"
									alt=""
									srcset=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bg_title_out" style="padding-top:156px">
				<div
					:class="
						teacherScroll && teacherTime == 0
							? 'bg_title2 bg_title_apear2'
							: 'bg_title2'
					"
					:style="{
						opacity: teacherTime == 1 ? '1' : '0',
						'animation-delay': '0ms',
						textAlign: 'center'
					}"
				>
					FACULTY TEAM
				</div>
				<div
					class="title_out2 flexVerticalCenter"
					:style="{ marginBottom: '40px' }"
				>
					<div
						:class="
							teacherScroll && teacherTime == 0
								? 'big_title2 title_apear2'
								: 'big_title2'
						"
						:style="{
							opacity: teacherTime == 1 ? '1' : '0',
							'animation-delay': '45ms',
							textAlign: 'center',
							paddingBottom: '0'
						}"
					>
						专业海归顾问团队
					</div>
				</div>
			</div>
		</div>
		<!-- 专业海归顾问团队 -->
		<div ref="teacher" class="teacher_out">
			<div
				:class="
					teacherScroll && teacherTime == 0
						? 'teacher_small_title title_apear2'
						: 'teacher_small_title'
				"
				:style="{
					opacity: teacherTime == 1 ? '1' : '0',
					'animation-delay': '90ms',
					textAlign: 'center'
				}"
			>
				为您提供丰富经验与知识的留学支持
			</div>
			<div
				:class="
					teacherScroll && teacherTime == 0 ? 'content_apear' : ''
				"
				:style="{
					opacity: teacherTime == 1 ? '1' : '0'
				}"
			>
				<div class="teacher_list_out_out flexBetween">
					<div class="teacher_btn" @click="fnPrev()" v-if="canScroll">
						<img
							v-if="currentClickNumber > 1"
							class="btn_active_icon"
							src="https://qn.juquedu.com/left_active_icon.png"
							alt=""
						/>
						<img
							v-else
							class="btn_active_icon"
							src="https://qn.juquedu.com/left_icon.png"
							alt=""
						/>
					</div>
					<div class="teacher_list_out flexBetween">
						<div class="teacher_list_box" :ref="`fixedBox`">
							<div
								class="teacher_list flexCenter"
								:style="
									`width:${signleWidth *
										teacherList.length}px;transform:translate(${scrollResultWidth}px,0);transition:0.7s;`
								"
							>
								<div
									class="teacher_imgs flexVerticalCenter"
									v-for="(item, d) in teacherList"
									:key="d + 't'"
								>
									<img
										style="border-radius:16px;width:100%;height:100%"
										:src="item.img1"
										alt=""
									/>
									<div class="teacher_imgs_bg_box">
										<div class="teacher_imgs_bg"></div>
										<div class="teacher_details">
											<div class="teacher_name">
												{{ item.name }}
											</div>
											<div class="teacher_des">
												{{ item.title }}
											</div>
											<div class="teacher_introduce">
												{{ item.desc }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="teacher_btn"
						@click="fnNext(activeName)"
						v-if="canScroll"
					>
						<img
							v-if="noScrollRight && teacherList.length > 4"
							class="btn_active_icon"
							src="https://qn.juquedu.com/right_active_icon.png"
							alt=""
						/>
						<img
							v-else
							class="btn_active_icon"
							src="https://qn.juquedu.com/right_icon.png"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
		<ContactPopup ref="contactPopup"></ContactPopup>
	</div>
</template>

<script>
import ContactPopup from '@/components/contactPopup.vue'
export default {
	data() {
		return {
			currentProgressId: '',
			scrollResultWidth: 0, //transform滚动的距离
			signleWidth: 303, //单个流程的宽度
			activeName: 0,
			currentClickNumber: 1,
			noScrollRight: true,
			canScroll: true,
			modularList: [
				{ name: 'video', scroll: '' },
				{ name: 'juqu', scroll: '' },
				{ name: 'service', scroll: '' },
				{ name: 'country', scroll: '' },
				{ name: 'teacher', scroll: '' }
			],
			scrollItem: 'video',
			juquImg: false,
			juquTime: 0,
			studyTime: 0,
			teacherTime: 0,
			countryTime: 0,
			juquScroll: false,
			studyScroll: false,
			teacherScroll: false,
			countryScroll: false,
			isVideo: false,
			studyList: [
				{
					img: 'https://qn.juquedu.com/service_bg1.png',
					title: '前期留学咨询与规划',
					content: [
						'申请学生宿舍(视具体情况而定)',
						'德国文化、留学生活讲解',
						'签证材料准备及过程指导'
					]
				},
				{
					img: 'https://qn.juquedu.com/service_bg2.png',
					title: '匹配最佳择校方案',
					content: [
						'作品集要求解读',
						'与德国院校教授沟通',
						'并协助前期赴德艺考'
					]
				},
				{
					img: 'https://qn.juquedu.com/service_bg3.png',
					title: '院校申请及签证指导',
					content: [
						'申请文书细节打磨',
						'协助各项材料办理',
						'全程高效把控服务'
					]
				},
				{
					img: 'https://qn.juquedu.com/service_bg4.png',
					title: '更多服务',
					content: [
						'行前指导面面俱到',
						'衣食住行全方位呵护',
						'生活学习攻略分享'
					]
				}
			],
			classificationList: [
				{ id: 1, title: '首页', name: 'home' },
				{ id: 2, title: '德国', name: 'Germany' },
				{ id: 3, title: '法国', name: 'French' },
				{ id: 4, title: '西班牙', name: 'Spain' },
				{ id: 5, title: '英美澳', name: 'UKUSAAustralia' },
				{ id: 6, title: '亚洲', name: 'Asia' }
			],
			teacherList: [],
			ops: {
				vuescroll: {},
				scrollPanel: {},
				rail: {
					keepShow: true
				},
				bar: {
					hoverStyle: true,
					onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
					background: '#707070', //滚动条颜色
					opacity: 0.5, //滚动条透明度
					'overflow-y': 'hidden'
				}
			},
			countryList: [],
			poster: require('@/assets/images/home/poster.jpg'),
			scrollTop: 0,
			isChangeLayout: false,
			videoHeight: 0,
			allData: { monthTotal: 0, total: 0 },
			timer: null,
			pauseNum: 0
		}
	},
	components: {
		ContactPopup
	},
	created() {
		//解决首次加载 未获取 countryTabData
		if (sessionStorage.getItem('countryTabData')) {
			this.allData = JSON.parse(sessionStorage.getItem('countryTabData'))
			this.setData()
		} else {
			this.timer = setInterval(() => {
				let alldata = JSON.parse(
					sessionStorage.getItem('countryTabData')
				)
				if (alldata) {
					this.allData = alldata
					this.setData()
					clearInterval(this.timer)
					this.timer = null
				}
			}, 300)
		}
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer)
			this.timer = null
		}
	},
	mounted() {
		window.addEventListener('scroll', this.windowScroll) //监听页面滚动
		this.modularList.forEach((item) => {
			var name = item.name
			if (name == 'video') {
				item.scroll =
					this.$refs[name].getBoundingClientRect().top +
					window.scrollY
			} else {
				item.scroll =
					this.$refs[name].getBoundingClientRect().top +
					window.scrollY -
					64
			}
		})
		this.videoHeight = this.$refs.video.offsetHeight
	},
	destroyed() {
		// 在组件生命周期结束的时候销毁。
		window.removeEventListener('scroll', this.windowScroll)
	},
	methods: {
		setData() {
			var data = []
			data = this.allData.countryList
			console.log('allData', this.allData)
			var list = []
			data.forEach((item) => {
				list.push(...item.teacherList)
			})
			this.countryList = this.allData.countryTkList
			list.sort((a, b) => {
				return a.sort - b.sort
			}) // 升序
			this.teacherList = list
			console.log(this.teacherList)
		},
		// 打开联系我们弹窗
		openContactPopup() {
			this.$refs.contactPopup.openPopup()
		},
		// 跳转
		toPage(item) {
			var query = {
				countryId: item.countryId
			}
			this.$router.push({
				path: '/country',
				query: query
			})
		},
		//点击上一个
		fnPrev() {
			//如果右点击的次数大于1，才可以左滚
			if (this.currentClickNumber > 1) {
				this.currentClickNumber -= 1
				this.noScrollRight = true
				this.fnScrollWidth('reduce')
			} else {
				return false
			}
		},
		//点击下一个
		fnNext() {
			const currentScrollWidth = this.$refs[`fixedBox`].clientWidth
			const canNumber = Math.floor(currentScrollWidth / this.signleWidth) //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (
				this.currentClickNumber + canNumber >=
				this.teacherList.length
			) {
				return
			}
			//说明放不下有滚动条
			if (this.teacherList.length > canNumber) {
				this.currentClickNumber += 1
				if (
					this.currentClickNumber + canNumber >=
					this.teacherList.length
				) {
					this.noScrollRight = false
				}
				this.fnScrollWidth('add')
			}
		},
		//translate的宽度
		fnScrollWidth(type) {
			let result = 0
			if (type === 'reduce') {
				result = 303
			} else if (type === 'add') {
				result = -303
			} else {
				result = 0
			}
			this.scrollResultWidth += result
		},
		// 监听页面滚动 获取滚动条位置
		windowScroll() {
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			if (
				scrollTop >=
				this.$refs.service.getBoundingClientRect().top +
					window.scrollY -
					100
			) {
				this.pauseNum += 1
				this.$refs.video2.pause()
			}
			if (this.scrollItem == 'video' && this.pauseNum > 0) {
				this.$refs.video2.pause()
			}
			if (
				scrollTop >=
					this.$refs.video.getBoundingClientRect().top +
						window.scrollY &&
				scrollTop <
					this.$refs.juqu.getBoundingClientRect().top +
						window.scrollY -
						780
			) {
				this.scrollItem = 'video'
			} else if (
				scrollTop >=
					this.$refs.juqu.getBoundingClientRect().top +
						window.scrollY -
						780 &&
				scrollTop <
					this.$refs.service.getBoundingClientRect().top +
						window.scrollY -
						780
			) {
				this.scrollItem = 'juqu'
				this.juquScroll = true
				this.juquImg = true
				setTimeout(() => {
					this.juquTime = 1
				}, 1000)
			} else if (
				scrollTop >=
					this.$refs.service.getBoundingClientRect().top +
						window.scrollY -
						780 &&
				scrollTop <
					this.$refs.country.getBoundingClientRect().top +
						window.scrollY -
						780
			) {
				this.scrollItem = 'service'
				this.studyScroll = true
				setTimeout(() => {
					this.studyTime = 1
				}, 1000)
			} else if (
				scrollTop >=
					this.$refs.country.getBoundingClientRect().top +
						window.scrollY -
						780 &&
				scrollTop <
					this.$refs.teacher.getBoundingClientRect().top +
						window.scrollY -
						780
			) {
				this.scrollItem = 'country'
				this.countryScroll = true
				setTimeout(() => {
					this.countryTime = 1
				}, 1000)
			} else {
				this.scrollItem = 'teacher'
				this.teacherScroll = true
				setTimeout(() => {
					this.teacherTime = 1
				}, 1000)
			}
		},
		select(item, i) {
			this.navIndex = i
			this.detailsImg = item.imgUrl
		},
		goToWebsite() {
			window.open('https://api.juquedu.com/website/index.html')
		}
	}
}
</script>

<style lang="scss" scoped>
.content_out {
	overflow-x: hidden;
	padding-top: 98px;
}
// 视频
.video_out_out {
	height: 100vh;
	width: 100%;
	min-width: 1200px;
	// position: relative;
	// z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	.video_out {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -1;
		width: 100%;
		.video {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}
	.video_mask {
		width: 100%;
		height: 100%;
		background: #000000;
		opacity: 0.3;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 0;
	}
	.video_content {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		flex-direction: column;
		.video_big_title {
			// height: 95px;
			overflow: hidden;
			font-size: 40px;
			font-weight: 400;
			color: #ffffff;
			display: flex;
			align-items: baseline;
			justify-content: center;
			div {
				font-size: 120px;
				font-weight: bold;
				font-style: oblique;
				padding: 0 18px;
				height: 120px;
			}
		}
		.video_english_img {
			width: 737px;
			height: 60px;
			// position: absolute;
			margin-top: -13px;
		}
		.video_small_title {
			overflow: hidden;
			font-size: 40px;
			font-weight: 400;
			color: #ffffff;
			margin-top: -9px;
		}
		.video_service_num {
			width: 510px;
			height: 61px;
			font-size: 24px;
			font-weight: 400;
			color: #e9e9e9;
			position: relative;
			margin-top: 50px;
			.video_service_num_bg {
				width: 100%;
				height: 100%;
				background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0) 0%,
					#7480b2 38%,
					#8590c2 65%,
					rgba(0, 0, 0, 0) 100%
				);
				opacity: 0.7;
				position: absolute;
				z-index: -1;
			}
		}
		.play_icon_out {
			height: 66px;
			overflow: hidden;
			.play_icon {
				width: 66px;
				height: 66px;
				cursor: pointer;
			}
		}
	}
}
.big_text_apear {
	animation: big_text_apear 0.8s forwards;
	display: inline-block;
	margin: 0;
	transform: translateY(100px);
	-ms-transform: translateY(100px);
	-webkit-transform: translateY(100px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(100px); /*兼容-moz-引擎浏览器*/
}
@keyframes big_text_apear {
	from {
		transform: translateY(100px);
	}
	to {
		transform: translateY(0);
	}
}
.small_text_apear {
	animation: small_text_apear 0.8s forwards;
	display: inline-block;
	margin: 0;
	transform: translateY(100px);
	-ms-transform: translateY(100px);
	-webkit-transform: translateY(100px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(100px); /*兼容-moz-引擎浏览器*/
}
@keyframes small_text_apear {
	from {
		transform: translateY(100px);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes tds-keyframe--bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(5px);
	}
	60% {
		transform: translateY(3px);
	}
}
// 关于聚趣
.juqu_out {
	width: 100%;
	position: relative;
	background: #ffffff;
	background-image: url('https://qn.juquedu.com/video_bg.png');
	background-size: cover;
	background-position: center center;
	.juqu {
		width: 1200px;
		margin: 0 auto;
		flex-direction: column;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		padding: 55px 0 50px 0;
		box-sizing: border-box;
		.juqu_content_out {
			width: 1073px;
			position: relative;
			border-radius: 16px;
			.juqu_content_icon {
				width: 236px;
				height: 179px;
				position: absolute;
				right: 59px;
				top: -41px;
				z-index: 99;
			}
		}
	}
	.juqu_app {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		.juqu_app_img {
			width: 405px;
			height: 487px;
			position: absolute;
			display: none;
		}
		.item_img1 {
			top: -90px;
			left: -70px;
		}
		.item_img2 {
			top: -186px;
			left: 261px;
		}
		.item_img3 {
			top: 215px;
			left: 19px;
		}
		.item_img4 {
			top: -404px;
			left: 678px;
		}
		.item_img5 {
			top: 171px;
			left: 317px;
		}
		.item_img6 {
			top: -344px;
			right: 274px;
		}
		.item_img7 {
			top: -380px;
			right: -19px;
		}
		.item_img8 {
			top: 25px;
			right: 224px;
		}
		.item_img9 {
			top: -186px;
			right: -175px;
		}
		.item_img10 {
			top: 222px;
			right: 69px;
		}
		.item_img11 {
			top: 98px;
			right: -278px;
		}
	}
}
.bg_title_out {
	width: 100%;
	position: relative;
	overflow: hidden;
	flex: 1;
	.bg_title {
		font-size: 56px;
		font-weight: 900;
		color: #333333;
		opacity: 0.1;
		position: absolute;
		top: 10px;
	}
	.title_out {
		flex-direction: column;
		.big_title {
			font-size: 48px;
			font-weight: bold;
			color: #333333;
		}
		.small_title {
			font-size: 20px;
			font-weight: 400;
			color: #333333;
		}
	}
	.bg_title2 {
		width: 100%;
		font-size: 50px;
		font-weight: 900;
		color: #d5e0ff;
	}
	.title_out2 {
		width: 100%;
		color: #333333;
		margin: -35px 0 60px 0;
		.big_title2 {
			width: 100%;
			font-size: 48px;
			font-weight: bold;
			padding-bottom: 15px;
			span {
				color: #4d4dd9;
			}
		}
		.small_title2 {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
		}
	}
}
.content_apear {
	animation: content_apear 0.8s forwards;
}
@keyframes content_apear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.title_apear {
	animation: title_apear 1s forwards;
	display: inline-block;
	margin: 0;
	transform: translateY(136px);
	-ms-transform: translateY(136px);
	-webkit-transform: translateY(136px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(136px); /*兼容-moz-引擎浏览器*/
}
@keyframes title_apear {
	0% {
		opacity: 0;
		transform: translateY(136px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.bg_title_apear {
	animation: bg_title_apear 1s forwards;
	display: inline-block;
	margin: 0;
	transform: translateY(136px);
	-ms-transform: translateY(136px);
	-webkit-transform: translateY(136px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(136px); /*兼容-moz-引擎浏览器*/
}
@keyframes bg_title_apear {
	0% {
		opacity: 0;
		transform: translateY(136px);
	}
	100% {
		opacity: 0.1;
		transform: translateY(0);
	}
}
.title_apear2 {
	animation: title_apear2 1s forwards;
	display: inline-block;
	// margin: 0;
	transform: translateY(136px);
	-ms-transform: translateY(136px);
	-webkit-transform: translateY(136px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(136px); /*兼容-moz-引擎浏览器*/
}
@keyframes title_apear2 {
	0% {
		opacity: 0;
		transform: translateY(136px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.bg_title_apear2 {
	animation: bg_title_apear2 1s forwards;
	display: inline-block;
	margin: 0;
	transform: translateY(136px);
	-ms-transform: translateY(136px);
	-webkit-transform: translateY(136px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(136px); /*兼容-moz-引擎浏览器*/
}
@keyframes bg_title_apear2 {
	0% {
		opacity: 0;
		transform: translateY(136px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.img_apear {
	animation: img_apear 1s forwards;
	margin: 0;
	transform: translateY(500px);
	-ms-transform: translateY(500px);
	-webkit-transform: translateY(500px); /*兼容-webkit-引擎浏览器*/
	-moz-transform: translateY(500px); /*兼容-moz-引擎浏览器*/
}
@keyframes img_apear {
	from {
		top: -500px;
		transform: translateX(500px);
	}
	to {
		transform: translateY(0);
		transform: translateX(0);
	}
}
// 留学服务
.service_out {
	width: 100%;
	min-height: 1001px;
	background: #eaefff;
	position: relative;
	.service_inner {
		width: 1200px;
		margin: 0 auto;
		padding-top: 50px;
		box-sizing: border-box;
		.bg_title_box {
			.service_contact {
				width: 169px;
				height: 49px;
				background: #4d4dd9;
				border-radius: 28px;
				font-size: 24px;
				font-weight: 400;
				color: #ffffff;
				cursor: pointer;
				.service_contact_img {
					width: 25px;
					height: 25px;
					margin-right: 8px;
				}
			}
		}
		.service {
			height: 297px;
			overflow: hidden;
			float: left;
			padding: 30px 36px;
			box-sizing: border-box;
			background-size: cover;
			box-shadow: 0px 3px 20px 1px rgba(139, 142, 164, 0.16);
			border-radius: 30px;
			position: relative;
			.service_title::before {
				content: '';
				display: inline-block;
				width: 4px;
				height: 25px;
				background: #333333;
				margin-right: 10px;
			}
			.service_title {
				font-size: 34px;
				font-weight: 800;
				color: #333333;
				span {
					font-size: 24px;
					font-weight: 400;
					color: #666666;
				}
			}
			.service_content::before {
				content: '';
				display: inline-block;
				width: 6px;
				height: 6px;
				background: #333333;
				margin: 0 10px 4px 0;
				border-radius: 50%;
			}
			.service_content {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				white-space: pre-line;
				padding-top: 30px;
			}
		}
		.service:nth-child(2) {
			width: 531px;
			margin: 0 29px 29px 0;
		}
		.service:nth-child(3) {
			width: 640px;
			margin: 0 0 29px 0;
		}
		.service:nth-child(4) {
			width: 640px;
			margin: 0 29px 0 0;
		}
		.service:nth-child(5) {
			width: 531px;
		}
	}
}
// 留学国家推荐
.country_out {
	width: 100%;
	height: 1571px;
	position: relative;
	background-image: url('https://qn.juquedu.com/country_bg.png');
	background-size: cover;
	background-position: center center;
	.country {
		width: 1200px;
		height: 1158px;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 20px 20px 20px 20px;
		position: relative;
		.country_list {
			width: 100%;
			height: 100%;
			position: relative;
			.country_bg {
				width: 414px;
				height: 414px;
				position: absolute;
				top: 287px;
				left: 312px;
				border: 80px solid #ecf1ff;
				border-radius: 50%;
				.country_earth {
					width: 348px;
					height: 348px;
				}
			}
			.country_item {
				position: absolute;
				flex-direction: column;
				color: #333333;
				border-radius: 16px;
				.country_info {
					position: relative;
					.country_chinese {
						font-size: 30px;
						font-weight: bold;
						.country_flag {
							width: 38px;
							height: 28px;
							margin: 4px 0 0 12px;
						}
					}
					.country_num {
						font-size: 20px;
						font-weight: 400;
						color: #666666;
						padding: 10px 0;
						text-align: center;
						span {
							font-size: 24px;
							font-weight: Heavy;
							color: #333333;
							margin-right: 5px;
						}
					}
					.country_original {
						font-size: 24px;
						font-weight: 900;
						color: #333333;
						padding-bottom: 12px;
						text-align: center;
					}
					.country_more {
						width: 88px;
						height: 33px;
						background: #ebebeb;
						border-radius: 28px;
						font-size: 15px;
						font-weight: 400;
						color: #333333;
						margin: 0 auto;
					}
				}
				.country_flag_box {
					width: 138px;
					height: 138px;
					// align-items: flex-end;
					.country_flag_bg {
						width: 106px;
						height: 106px;
						background: #ffffff;
						box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
						border: 1px solid #ffffff;
						border-radius: 50%;
						.country_sign {
							width: 84px;
							height: 84px;
							border-radius: 50%;
						}
					}
				}
			}
			.country_item:hover {
				transform: scale(1.1);
				cursor: pointer;
			}
			.country_item:nth-child(2) {
				top: 65px;
				z-index: 10;
				.country_info {
					padding-bottom: 20px;
				}
			}
			.country_item:nth-child(3) {
				top: 135px;
				left: 648px;
				z-index: 9;
				.country_info {
					left: 75px;
				}
			}
			.country_item:nth-child(4) {
				top: 254px;
				left: 732px;
				z-index: 8;
				.country_info {
					left: 159px;
					bottom: -73px;
				}
			}
			.country_item:nth-child(5) {
				top: 405px;
				left: 740px;
				z-index: 7;

				.country_info {
					left: 181px;
					bottom: -175px;
				}
			}
			.country_item:nth-child(6) {
				top: 536px;
				left: 648px;
				z-index: 6;
				.country_info {
					left: 134px;
					bottom: -295px;
				}
			}
			.country_item:nth-child(7) {
				top: 579px;
				z-index: 5;
				.country_info {
					bottom: -317px;
				}
			}
			.country_item:nth-child(8) {
				top: 536px;
				left: 355px;
				z-index: 2;
				.country_info {
					left: -134px;
					bottom: -295px;
				}
			}
			.country_item:nth-child(9) {
				top: 405px;
				left: 266px;
				z-index: 3;

				.country_info {
					left: -181px;
					bottom: -175px;
				}
			}
			.country_item:nth-child(10) {
				top: 254px;
				left: 274px;
				z-index: 3;
				.country_info {
					left: -159px;
					bottom: -73px;
				}
			}
			.country_item:nth-child(11) {
				top: 135px;
				left: 358px;
				z-index: 4;
				.country_info {
					left: -75px;
				}
			}
		}
	}
}
// 专业海归顾问团队
.teacher_out {
	width: 100%;
	height: 676px;
	background: #eaefff;
	position: relative;
	.teacher_small_title {
		width: 100%;
		font-size: 30px;
		font-weight: bold;
		color: #333333;
		text-align: center;
		padding-bottom: 60px;
	}
	.teacher_classification_out {
		width: 1200px;
		height: 53px;
		background: #f6f8ff;
		box-shadow: 0px 3px 6px 1px rgba(112, 113, 142, 0.16);
		border-radius: 27px;
		margin: 52px auto 0;
		.teacher_classification {
			// width: 103px;
			height: 53px;
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			margin-right: 20px;
			cursor: pointer;
		}
		.teacher_classification:last-child {
			margin-right: 0;
		}
		.teacher_classification_active {
			background: #f1f1f3;
			border-radius: 12px;
		}
	}
	.teacher_list_out_out {
		width: 1200px;
		margin: 0 auto;
		position: relative;
		.teacher_list_out {
			width: 1200px;
			margin: 0 auto;
			position: relative;
			.teacher_list_box {
				width: 100%;
				flex: 1;
				overflow: hidden;
			}
			.teacher_list {
				white-space: nowrap;
				.teacher_imgs {
					width: 289px;
					height: 400px;
					margin-right: 14px;
					background: linear-gradient(
						51deg,
						#848ea3 0%,
						#4a505c 100%
					);
					border-radius: 16px;
					cursor: pointer;
					position: relative;
					.teacher_imgs_bg_box {
						width: 100%;
						height: 100%;
						display: none;
						border-radius: 16px;
						position: absolute;
						padding: 60px 16px 40px;
						box-sizing: border-box;
						.teacher_imgs_bg {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: #000000;
							opacity: 0.42;
							border-radius: 16px;
						}
						.teacher_details {
							width: 100%;
							height: 100%;
							color: #ffffff;
							position: relative;
							.teacher_name {
								font-size: 30px;
								font-weight: bold;
							}
							.teacher_des {
								font-size: 18px;
								font-weight: 400;
								padding: 8px 0 30px;
								white-space: pre-line;
							}
							.teacher_introduce {
								width: 100%;
								line-height: 30px;
								font-size: 16px;
								font-weight: 400;
								white-space: pre-line;
								display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
								-webkit-line-clamp: 7; /*显示的行数；如果要设置2行加...则设置为2*/
								overflow: hidden; /*超出的文本隐藏*/
								text-overflow: ellipsis; /* 溢出用省略号*/
								-webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
							}
						}
					}
				}
				.teacher_imgs:hover {
					.teacher_imgs_bg_box {
						display: block;
					}
				}
				.teacher_imgs:last-child {
					margin-right: 0;
				}
			}
		}
		.teacher_btn {
			width: 70px;
			height: 70px;
			position: absolute;
			z-index: 2;
			.btn_icon {
				width: 100%;
				height: 100%;
			}
			.btn_active_icon {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
		.teacher_btn:nth-child(1) {
			left: -35px;
		}
		.teacher_btn:last-child {
			right: -35px;
		}
	}
}
</style>
